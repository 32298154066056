import { render, staticRenderFns } from "./ProjectFormList.vue?vue&type=template&id=0ec44698"
import script from "./ProjectFormList.vue?vue&type=script&lang=js"
export * from "./ProjectFormList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ec44698')) {
      api.createRecord('0ec44698', component.options)
    } else {
      api.reload('0ec44698', component.options)
    }
    module.hot.accept("./ProjectFormList.vue?vue&type=template&id=0ec44698", function () {
      api.rerender('0ec44698', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Setting/ProjectFormSetting/ProjectFormList.vue"
export default component.exports