<template>
	<div class="content-wrap">
		<!--IItReport报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
      <CtmsSearchBoxItem :span="1" label="项目名称">
      <Input
        v-model="searchParams.projectName"
        placeholder="项目名称"
        clearable
      ></Input>
    </CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="创建日期">
				<DatePicker
					type="daterange"
					placeholder="请选择"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目阶段">
				<Select
					v-model="searchParams.projectStatus"
					clearable
					:transfer="true"
					placeholder="请选择项目阶段"
				>
					<Option
						:value="item.obj.nickname"
						v-for="item in projectStep"
						:key="item.obj.nickname"
						>{{ item.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="研究型病房项目">
        <Select
          v-model="searchParams.researchWard"
          :transfer="true"
          clearable
          placeholder="请选择类型"
        >
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </CtmsSearchBoxItem>
		</CtmsSearchBox>
		<!-- <CtmsAction>
			<Button @click="exportData">导出IST报表</Button>
		</CtmsAction> -->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      :page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="information">
				<span>
					{{
						row.information
							? inAccountInfo.find(item => item.value === row.information).label
							: ""
					}}
				</span>
			</template>
		</CtmsDataGrid>
	</div>
</template>

<script>
import publicApi from "@/api/public"
import reportApi from "@/api/report/report"
import { mapState } from "vuex"

const { publicGetDictionaryList } = publicApi
const { apiGetIitReport } = reportApi

export default {
	name: "IstReport",
	data() {
		return {
			loading: false,
			times: [],
			searchParams: {
        		projectName: "",
				projectStatus: "",
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : "",
				researchWard: ""
			},
			oldSearchParams: {
        		projectName: "",
				projectStatus: "",
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : "",
				researchWard: ""
			},
			projectStep: [],
			data: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 250
				},
				{
					title: "项目所处大阶段",
					key: "projectMajorStage",
					minWidth: 150
				},
				{
					title: "立项日期",
					key: "applyDate",
					minWidth: 150
				},
				{
					title: "伦理受理号",
					key: "ethicsAcceptNo",
					minWidth: 120
				},
				{
					title: "伦理受理日期",
					key: "ethicsAcceptDate",
					minWidth: 120
				},
				{
					title: "首次伦理批件号",
					key: "firstEthicsAcceptNo",
					minWidth: 140
				},
				{
					title: "首次伦理批件日期",
					key: "firstEthicsAcceptData",
					minWidth: 150
				},
				{
					title: "是否办理人类遗传办批件",
					key: "whetherHgrac",
					minWidth: 130
				},
				{
					title: "人类遗传办批件申请事项",
					key: "hgcApprovalAction",
					minWidth: 120
				},
				{
					title: "人类遗传办批件申请类别",
					key: "hgcApprovalCategory",
					minWidth: 150
				},
				{
					title: "人类遗传办批件-通知时间",
					key: "hgcApprovalNoticeData",
					minWidth: 150
				},
				{
					title: "人遗审批/备案日期",
					key: "hgcApprovalData",
					minWidth: 120
				},
				{
					title: "人遗批件号",
					key: "hgcApprovalNo",
					minWidth: 120
				},
				{
					title: "合同签署日期",
					key: "contractDate",
					minWidth: 120
				},
				{
					title: "合同金额（万元）",
					key: "contractAmount",
					minWidth: 150
				},
				{
					title: "首笔款到账金额",
					key: "firstReceivedPayAmount",
					minWidth: 140
				},
				{
					title: "首笔款到账日期",
					key: "firstReceivedPayDate",
					minWidth: 140
				},
				{
					title: "累积已到账金额",
					key: "receivedPayAmount",
					minWidth: 140
				},
				{
					title: "项目启动日期",
					key: "projectStartDate",
					minWidth: 120
				},
				{
					title: "本机构第一例受试者签署知情同意书的日期",
					key: "firstSubjectSignDate",
					minWidth: 180
				},
				{
					title: "本机构第一例受试者入组的日期",
					key: "firstSubjectGroupDate",
					minWidth: 140
				},
				{
					title: "试验终止日期",
					key: "eotDate",
					minWidth: 120
				},
				{
					title: "招募状态",
					key: "recruitStatus",
					minWidth: 140
				},
				{
					title: "已筛选例数",
					key: "filteredAmount",
					minWidth: 120
				},
				{
					title: "已入组例数",
					key: "groupAmount",
					minWidth: 120
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
	created() {
		this.getProjectStepInfo()
		this.initData()
	},
	methods: {
		// 项目阶段
		getProjectStepInfo() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList({ type: 25, isDel: 0 })
				if (res) {
					const arr = res.data && res.data.length ? res.data[0].leaf : []
					const info = arr.map(item => ({
						...item,
						obj: {
							...item.obj,
							nickname: Number(item.obj.nickname)
						}
					}))
					this.projectStep = info
				}
				this.loading = false
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetIitReport({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.data = res.records || []
					this.page.total = res.total
				}
				this.loading = false
			})
		},
		// exportData() {
		// 	/*
		// 	 * classification 费用分类：1、机构 2、伦理
		// 	 * type 费用类型：1、入账 2、出账
		// 	 * createTimeStart 入/出账时间-开始
		// 	 * createTimeEnd 入/出账时间-结束
		// 	 * */
		// 	const projectName = this.searchParams.projectName || ""
		// 	const type = this.searchParams.type || ""
		// 	const start = this.searchParams.createTimeStart || ""
		// 	const end = this.searchParams.createTimeEnd || ""
		// 	const approvedStatus =
		// 		this.searchParams.approvedStatus === undefined
		// 			? ""
		// 			: this.searchParams.approvedStatus
		// 	const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&createTimeStart=${start}&createTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
		// 	window.open(url)
		// },
		// 重置
		reset() {
			this.times = []
			this.searchParams.projectStatus = ""
			this.searchParams.researchWard = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.createTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.createTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
