<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="项目类型">
				<Input placeholder="请输入" v-model="searchParams.typeNameLike" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_pharmacy_add') > -1"
				@click="handleAddShow"
				>新增项目类型</Button
			>
		</CtmsAction>

		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<!--新增项目类型-->
		<Modal
			v-model="addVisible"
			:title="title"
			:styles="{ top: '20%' }"
			:mask-closable="false"
			@on-cancel="cancel"
		>
			<Form
				ref="proTypeForm"
				:model="proTypeForm"
				:rules="rules"
				:label-width="120"
			>
				<FormItem label="项目类型：" prop="typeName">
					<Input
						v-model.trim="proTypeForm.typeName"
						placeholder="请输入项目类型名称"
					></Input>
				</FormItem>
				<FormItem label="类型简写：" prop="code">
					<Input
						v-model.trim="proTypeForm.code"
						placeholder="请输入类型简写"
					></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="cancel">取消</Button>
				<Button type="primary" :loading="loading" @click="ok">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import api from "@/api/project/project"
import { mapState } from "vuex"

const {
	apiGetProjFormList,
	apiAddFormType,
	apiEditFormType,
	apiDelFormType,
	apiCopyFormSetting
} = api

export default {
	name: "projectFormList",
	data() {
		return {
			title: "新增项目类型",
			actionType: "add",
			listData: [],
			columns: [
				{ title: "项目类型", key: "typeName" },
				{ title: "类型简写", key: "code" },
				{
					title: "操作",
					key: "action",
					renderButton: params => {
						const actionList = [
							{
								label: "复制",
								on: {
									click: this.handleCopy
								}
							},
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "表单设计",
								on: {
									click: () => {
										this.$router.push({
											path: "/setting/project-form/config",
											query: {
												id: params.row.id
											}
										})
									}
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleDelete
								},
								confirm: {
									title: "您确认删除该项目类型吗？"
								}
							}
						]
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			proTypeForm: {
				typeName: "",
				code: ""
			},
			rules: {
				typeName: {
					required: true,
					message: "项目类型不能为空",
					trigger: "blur"
				},
				code: { required: true, message: "类型简写不能为空", trigger: "blur" }
			},
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				typeNameLike: ""
			},
			oldSearchParams: {}
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProjFormList({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 新增
		handleAddShow() {
			this.$refs.proTypeForm.resetFields()
			this.title = "新增项目类型"
			this.updateId = ""
			this.actionType = "add"
			this.addVisible = true
		},
		// 修改
		handleUpdateShow({ row }) {
			this.proTypeForm.typeName = row.typeName
			this.proTypeForm.code = row.code
			this.title = "修改项目类型"
			this.updateId = row.id
			this.actionType = "edit"
			this.addVisible = true
		},
		// 复制
		handleCopy({ row }) {
			this.$refs.proTypeForm.resetFields()
			this.title = "复制项目类型"
			this.updateId = row.id
			this.actionType = "copy"
			this.addVisible = true
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		ok() {
			this.$refs.proTypeForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							if (this.actionType === "edit") {
								// 修改
								res = await apiEditFormType({
									...this.proTypeForm,
									projectConfigId: this.updateId
								})
							} else {
								// 复制
								res = await apiCopyFormSetting({
									...this.proTypeForm,
									projectConfigId: this.updateId
								})
							}
						} else {
							// 新增
							res = await apiAddFormType({
								...this.proTypeForm
							})
						}
						if (res) {
							this.$Message.success("操作成功")
							this.addVisible = false
							this.searchHandle()

							// 更新项目类型列表
							this.$store.dispatch("project/getProjType")
						}
						this.loading = false
					})
				}
			})
		},
		cancel() {
			this.addVisible = false
		},
		// 重置
		reset() {
			this.searchParams = {
				typeNameLike: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 删除
		handleDelete({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelFormType(row.id)
				if (res) {
					this.$Message.success("操作成功！")
					this.searchHandle()
				} else {
					this.loading = false
				}
			})
		}
	}
}
</script>
